import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { ClientNode, LeadNode } from '@generated/graphql';
import {
  EMAIL_V2,
  INPUT_V2,
  PHONE_V2,
  REPEAT_V2,
  TEXTAREA_V2,
  TYPEAHEAD_V2,
} from '@app/formly/helpers/fields';

import { isNil } from 'lodash';
import {
  ClientsService,
  mapReferenceClientToDDItem,
} from '@app/shared/services/clients/clients.service';
import { GraphqlID } from '@app/shared/types';

export type ContactDetailsForm = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phones?: string[];
  emails?: string[];
  referenceClientId?: GraphqlID;
  note?: string;
  contactNotes?: string;
};

@Component({
  selector: 'contact-form-v2',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent extends RadarFormAbstract implements OnInit {
  @Input() data: LeadNode | ClientNode;
  @Input() isClient: boolean;

  constructor(private clientsService: ClientsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      INPUT_V2({
        key: 'firstName',
        props: {
          label: 'First Name',
          placeholder: 'First Name',
        },
      }),
      INPUT_V2({
        key: 'middleName',
        props: {
          label: 'Middle Name',
          placeholder: 'Middle Name',
        },
      }),
      INPUT_V2({
        key: 'lastName',
        props: {
          label: 'Last Name',
          placeholder: 'Last Name',
        },
      }),
      REPEAT_V2({
        key: 'phones',
        props: {
          label: 'Phone',
        },
        fieldArray: PHONE_V2({
          key: 'phones',
          expressionProperties: this.expressionPhonesProperties,
        }),
        expressionProperties: this.expressionPhonesProperties,
      }),
      REPEAT_V2({
        key: 'emails',
        props: {
          label: 'Email',
        },
        fieldArray: EMAIL_V2({
          key: 'emails',
          props: {
            placeholder: 'Email',
          },
          expressionProperties: this.expressionEmailsProperties,
        }),
        expressionProperties: this.expressionEmailsProperties,
      }),
      TYPEAHEAD_V2(
        {
          key: 'referenceClientId',
          props: {
            label: 'Referral',
            placeholder: 'Choose referral',
            asyncOptionsFetch: (searchValue: string) =>
              this.clientsService.getClientsBySalesIds(searchValue, true),
            initial: this.data.referenceClient?.id
              ? mapReferenceClientToDDItem(this.data.referenceClient)
              : null,
          },
        },
        this.clientsService.getClientsBySalesIds('', true),
      ),
      TEXTAREA_V2({
        key: this.isClient ? 'note' : 'contactNotes',
        props: {
          label: 'Contact Notes',
          placeholder: 'Contact Notes',
        },
      }),
    ];
  }

  submitFormV2() {
    this.formModel['phones'] = this.formGroup
      .get('phones')
      ?.value?.filter((phone: string) => !isNil(phone) && phone !== '');

    this.formModel['emails'] = this.formGroup
      .get('emails')
      ?.value?.filter((email: string) => !isNil(email) && email !== '');

    super.submitFormV2();
  }

  expressionPhonesProperties = {
    'props.required': () => this.isRequiredByKey('emails'),
  };

  expressionEmailsProperties = {
    'props.required': () => this.isRequiredByKey('phones'),
  };

  isRequiredByKey(key: string) {
    return !(this.formModel[key]?.length && this.formModel[key]?.every((i: string) => !!i));
  }
}
